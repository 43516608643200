import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/ApiDocumentationLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Get System Version`}</h1>
    <h2>{`GET `}<inlineCode parentName="h2">{`/v1/system/version`}</inlineCode></h2>
    <p>{`Get the API system version number.`}</p>
    <h3>{`Responses `}<inlineCode parentName="h3">{`200`}</inlineCode></h3>
    <p>{`Body`}</p>
    <p>{`Content: `}<inlineCode parentName="p">{`application/json`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
    "version": [string] Version code
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      